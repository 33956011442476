import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {createPortal} from 'react-dom';
import './index.css';
import {ImageLoader} from './helpers/ImageLoader';
import * as device from 'react-device-detect';

for ( let key in device ) {
  if ( key.startsWith( 'is' ) && device[ key ] ) {
    document.body.classList.add( key );
  }
}

export const modalPortal = ( element ) => {
  return createPortal( element, document.getElementById( 'modal' ) );
};

/* -_- */
export function popupPlace( element ) {
  return createPortal( element, document.getElementById( 'popup-root' ) );
}

export function tooltipPlace( element ) {
  return createPortal( element, document.getElementById( 'tooltip-root' ) );
}

/* IMAGE LOADER */
const { REACT_APP_STATIC_FILES_URL, REACT_APP_POOL_FILES_URL } = process.env;

const poolLoaderPath = localStorage.getItem( 'DEBUG_POOL_FILES_URL' ) || REACT_APP_POOL_FILES_URL;

export const poolLoader = new ImageLoader( { path: `${ poolLoaderPath }/` } );
export const staticLoader = new ImageLoader( { path: `${ REACT_APP_STATIC_FILES_URL }/` } );
export const publicLoader = new ImageLoader( { path: `${ window.location.origin }/images/` } );

/* RENDER */
ReactDOM.createRoot( document.getElementById( 'root' ) ).render( <App/> );
