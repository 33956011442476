export const bool = (arg ) => !!arg;
export const isArray = ( arg ) => Array.isArray( arg );
export const isBoolean = ( arg ) => typeof arg === 'boolean';
export const isUndefined = ( arg ) => arg === undefined;
export const isNull = ( arg ) => arg === null;
export const isSet = ( arg ) => !isNull( arg ) && !isUndefined( arg );
export const isString = ( arg ) => typeof arg === 'string';
export const isInfinity = ( arg ) => arg === Infinity ? 1 : arg === -Infinity ? -1 : 0;
export const isNumber = ( arg ) => typeof arg === 'number' && !isNaN( arg ) && !isInfinity( arg );
export const isNumeric = ( arg ) => /^[-+]?\d+(\.\d+)?$/.test( arg );
export const isInt = ( arg ) => isNumber( arg ) && arg % 1 === 0;
export const isFloat = ( arg ) => isNumber( arg ) && arg % 1 !== 0;
export const isObject = ( arg ) => typeof arg === 'object' && !isNull( arg );
export const isFunction = ( arg ) => typeof arg === 'function';
export const isNode = ( arg ) => arg instanceof Node;
export const isElement = ( arg ) => arg instanceof Element;
export const isBlob = ( arg ) => arg instanceof Blob;
export const isPromise = ( arg ) => arg instanceof Promise;
export const isIterable = ( arg ) => isObject( arg ) && arg[Symbol.iterator];
