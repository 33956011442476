import React, {lazy, Suspense} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Route, Routes} from 'react-router';
import {VIEW_ROUTE} from './utils/consts';
import {ApolloProvider} from '@apollo/client';
import {apolloAuthClient} from './http/apollo';
import {ImagesCacheProvider} from './components/ImagesCache';
import {isIFrame} from './helpers/utiles';

let ViewLazy = lazy( () => import( './pages/View/View' ) );
let AppRouterLazy = lazy( () => import( './AppRouter' ) );

const App = () => {
	return (
			<ApolloProvider client={ apolloAuthClient }>
				<ImagesCacheProvider>
					<BrowserRouter>
						<Routes>
							<Route path={ VIEW_ROUTE + '/:hash' } element={ <Suspense><ViewLazy/></Suspense> }/>
							{ isIFrame ? <>
								<Route path="*" element={ <h1>Iframe is forbidden for this page!</h1> }/>
							</> : <>
								<Route path="*" element={ <Suspense><AppRouterLazy/></Suspense> }/>
							</> }
						</Routes>
					</BrowserRouter>
				</ImagesCacheProvider>
			</ApolloProvider>
	);
};

export default App;
