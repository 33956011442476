import {createContext, memo, useContext, useEffect, useMemo, useRef} from 'react';
import {isObject} from '../helpers/type';

export const ImageCacheContext = createContext();

export const ImagesCacheProvider = ( { cache, children } ) => {
	let imagesCache = useMemo( () => cache || new ImagesCache(), [ cache ] );

	return <ImageCacheContext.Provider value={ imagesCache }>{ children }</ImageCacheContext.Provider>;
};

let shadowStyle = { display: 'none' };

export const Image = memo( ( { crossOrigin, style, src, ...attributes } ) => {
	let imagesCache = useContext( ImageCacheContext );
	let ref = useRef();

	useEffect( () => {
		if ( ref.current && src ) {
			let img = imagesCache.getFreeImage( src, crossOrigin );

			if ( style ) {
				if ( isObject( style ) ) {
					style = Object.assign( document.createElement( 'div' ).style, style ).cssText;
				}

				attributes.style = style;
			}

			let names = Object.keys( attributes );

			names.forEach( name => img.setAttribute( name, attributes[ name ] ) );

			ref.current.parentNode.insertBefore( img, ref.current );

			return () => {
				imagesCache.freeImage( img );
				names.forEach( name => img.removeAttribute( name ) );
			};
		}
	} );

	return <div ref={ ref } style={ shadowStyle }/>;
} );

class ImagesCache {
	constructor() {
		this._cache = {};
	}

	loadImage( src, crossOrigin = 'anonymous' ) {
		let img = document.createElement( 'img' );

		img._load = new Promise( resolve => img.onload = img.onerror = img.onabort = resolve );
		img._load.finally( () => img.onload = img.onerror = img.onabort = null );
		img._free = true;

		img.crossOrigin = crossOrigin;
		img.src = src;

		return img;
	}

	async getImageAsync( src, crossOrigin = 'anonymous' ) {
		if ( src ) {
			let ary = this._cache[ src ] ||= [];

			let img = ary[ 0 ];

			if ( !img ) {
				img = this.loadImage( src, crossOrigin );
				ary.push( img );
			}

			await img._load;

			return img;
		}
	}

	getFreeImage( src, crossOrigin = 'anonymous' ) {
		if ( src ) {
			let ary = this._cache[ src ] ||= [];

			let img = ary.find( img => img._free );

			if ( !img ) {
				img = this.loadImage( src, crossOrigin );
				ary.push( img );
			}

			img._free = false;

			return img;
		}
	};

	freeImage( img ) {
		img.remove();
		img._free = true;
	};
}

export default ImagesCache;
